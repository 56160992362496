<template>
    <layout>
        <v-row 
            align="center"
            justify="center"
        >
        <v-card
            class="mt-7"
            width="500"
        >  
           
            <v-toolbar 
                color="primary"
                dark
            >
                <v-toolbar-title>Term Reports</v-toolbar-title>  
            </v-toolbar>
            <v-list two-line>
                <v-list-item-group
                    v-model="selected"
                    active-class="primary--text"                    
                >
                    <template v-for="(item, index) in items">
                        <v-list-item 
                            :key="item.term"
                            @click="displayReport(item)"
                            :disabled="!item.posted"
                        >
                            <template v-slot:default="{ active }">
                                <v-list-item-content>
                                    <v-list-item-title >
                                        {{ item.title }}
                                    </v-list-item-title>

                                    <v-list-item-subtitle
                                        class="text--primary"
                                    >
                                        {{ item.headline }}
                                    </v-list-item-subtitle>                                  

                                   
                                    <v-list-item-subtitle
                                    >
                                        {{ item.subtitle }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-list-item-action-text
                                        v-if="item.posted" 
                                    >
                                        Posted
                                    </v-list-item-action-text>
                                    <v-list-item-action-text
                                        v-else                                        
                                    >
                                        Not Posted
                                    </v-list-item-action-text>
                                    <v-icon
                                        v-if="!active"
                                        color="grey lighten-1"
                                    >
                                        mdi-star-outline
                                    </v-icon>

                                    <v-icon
                                        v-else
                                        color="yellow darken-3"
                                    >
                                        mdi-star
                                    </v-icon>

                                </v-list-item-action>

                                

                            </template>
                        </v-list-item>

                        <v-divider
                            v-if="index < items.length - 1"
                            :key="index"
                        ></v-divider>

                    </template>
                </v-list-item-group>
            </v-list>
            
        </v-card>
        </v-row>
        
        
        <v-dialog
            v-model="reportCard"
            max-width="700px"
            @click:outside="closeReport"
        >
            <v-card
               height="90vh" 
            >                
                <iframe 
                    ref="pdf" 
                    style="width:100%; height:100%" 
                    class="report-viewer" 
                    :src="src"
                ></iframe>              
                <v-overlay
                    absolute
                    :value="reportViewerOverlay"
                    color="white"
                    opacity="0.60"
                >
                    <v-progress-circular
                        indeterminate
                        size="64"
                        color="primary"
                    ></v-progress-circular>
                </v-overlay>     
            </v-card>
        </v-dialog>        
    </layout>    
</template>

<script>
import Layout from './layouts/App';
import { mapGetters, mapActions } from 'vuex';
export default {
    name: 'TermReport',
    components: {
        Layout,
    },

    created(){
        this.initialize();
    },
    
    data: () => ({
        reportCard: false,
        selected: 0,
        items: [],
        src: '',
        reportViewerOverlay: false,        
    }),
    computed:{
        ...mapGetters({
            user: 'auth/getUser',
            studentId: 'auth/getUserId',
            reportTerms: 'report/getReportTerms'                    
        }),
    },
    methods: {
        ...mapActions({
            getAcademicTerms: 'report/getAcademicTerms',
        }),       
        displayReport(item){           
            let term_id = item.term;
            let form_class_id = item.form_class_id;
            this.reportCard = true;
            this.reportViewerOverlay = true;
            this.$nextTick( function () {            
                this.$refs.pdf.onload = () => {
                    console.log('report is loaded');
                    this.reportViewerOverlay = false;
                }
            })              
            this.src = process.env.VUE_APP_API_URI + 
            `/api/report-card/${term_id}/` +
            `${form_class_id}/`+
            `${this.studentId}`
           
            console.log(this.src);
        },
        async initialize(){
            await this.getAcademicTerms({ id: this.studentId });
            // console.log(this.reportTerms);
            this.reportTerms.forEach(term => {
                if(term.posted){
                    this.items.push({
                        title: 'Form ' + term.form_level,
                        action: term.term_end,
                        headline: 'End of Term Report',
                        subtitle: 'Term ' + term.term + ' ' + term.academic_year,
                        term: term.academic_term_id,
                        form_class_id: term.form_class_id,
                        posted: true
                    })
                }
                else{
                    this.items.push({
                        title: 'Form ' + term.form_level,
                        action: term.term_end,
                        headline: 'End of Term Report',
                        subtitle: 'Term ' + term.term + ' ' + term.academic_year,
                        term: term.academic_term_id,
                        form_class_id: term.form_class_id,
                        posted: false
                    })
                }
                
            })
            // console.log(this.items);
        },
        closeReport(){
            console.log('report closing ...');
            this.src = '';
             this.selected = 0;
        }
    }
   
    
}
</script>